<style>
.notCoveredDisable {
  pointer-events: none;
}

body.default-style #top-bar .details2drop4v3{
  top: 50%;
  transform: translateY(-50%);
  margin:20px auto 0;
}
body.default-style .modal-v1 .modal-dialog {
  max-width: 540px !important;
}
@media (max-width: 501px){
  body.default-style .modal-v1 .modal-dialog .modal-content .modal-footer.justify-content-center .btn-secondary{
    height: 90px !important;
  }
}
#pa-packages > .container {
  max-width: 550px;
  width: 100%;
  min-width: 280px;
  padding: 0 10px;
}

#pa-packages .question-mark-red, #pa-packages .question-mark-red:active, #pa-packages .question-mark-red:focus {
    padding: 0;
    margin: 0;
    width: 20px;
    min-width: 20px;
    line-height: 20px;
    background-color: #fff!important;
    color: #ff5011!important;
    display: inline-block;
    border-radius: 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    border: 0 solid rgba(255,255,255,0)!important;
    -moz-box-shadow: #ff5011 0 1px 5px 0!important;
    box-shadow: #ff5011 0 1px 5px 0!important;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
}
#pa-packages .light-blue{
  color:#337ab7;
}

#pa-packages > .container {
  max-width: 550px;
}

#pa-packages header{
    border: none;
    background-image: linear-gradient(0deg,#003b71 0,#015caf 100%);
    color: #fff;
    padding: 2px 0 0;
    box-shadow: 0 2px 10px 1px rgb(0 0 0 / 10%);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    border-radius: 0 0 8px 8px;
    display: none;
}

#pa-packages header.fixed-menu{
    display: block;
}

#pa-packages header nav .nav-container{
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

#pa-packages header nav .nav-container > button.badge{
  position: absolute;
  top: 15px;
  border: 0;
  color:#ddd;
  font-size: 90%;
}

#pa-packages header nav .nav-container > button.badge:hover{
  color:#fff;
}

#pa-packages header nav .nav-container > button.badge.goback-btn{
  top: 50%;
  margin-top: -10px;
  margin-left: 10px;
  transform: translateY(-50%);
  left: 0;
}

#pa-packages header nav .nav-container > button.badge.installment-btn{
  right: 0;
}

#pa-packages header nav .nav-container > .price-div{
  width: 30%;
  display: block;
  margin: 0 auto;
  text-align: center;
  line-height: 1;
}

#pa-packages header nav .nav-container > .price-div > .price{
  font-size: 1.5rem;
}

#pa-packages header nav .nav-container > .price-div > .price > .total-price{
  font-size: 3rem;
}

#pa-packages header nav .nav-container > .price-div > .installment{
  font-size: 1.2rem;
}

@media (max-width: 767px){
  #pa-packages header nav .nav-container{
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 5px;
  }

  #pa-packages header nav .nav-container > button.badge{
    top:10px;
  }

  #pa-packages header nav .nav-container > .price-div{
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 1;
  }

  #pa-packages header nav .nav-container > .price-div > .price{
    font-size: 1.2rem;
  }

  #pa-packages header nav .nav-container > .price-div > .price > .total-price{
    font-size: 2.3rem;
  }

  #pa-packages header nav .nav-container > .price-div > .installment{
    font-size: 1rem;
  }
}

@media (max-width: 340px){
  #pa-packages header nav .nav-container > .price-div {
    margin-top: 25px;
  }

  #pa-packages header nav .nav-container > .price-div > .price > .total-price {
    font-size: 2rem;
  }
}

#pa-packages #section-container {
    margin: 0;
    padding: 0;
    position: relative;
}

#pa-packages #section-container section {
    margin: 0 10px 50px;
}

#pa-packages .alike-card {
    border-radius: 8px;
    padding: 30px 15px;
    width: 100%;
    min-width: 280px;
}

#pa-packages .alike-card.red-shadow {
    box-shadow: 0 3px 6px 3px rgb(153 153 153 / 100%);
    -webkit-box-shadow: 0 3px 6px 3px rgb(153 153 153 / 100%);
}

#pa-packages .alike-card.blue-shadow {
    box-shadow: 0 4px 5px 2px rgb(6 25 70 / 35%);
    -webkit-box-shadow: 0 4px 5px 2px rgb(6 25 70 / 35%);
}

#pa-packages .pay2stage2 .badge.g2ver{
    padding: 5px 14px;
    background-color: #196bb6;
    color: #fff;
    font-size: 80%;
    font-weight: 400;
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 0 1px 6px 2px hsl(0deg 0% 50% / 35%);
    box-shadow: 0 1px 6px 2px hsl(0deg 0% 50% / 35%);
}

#pa-packages .package-premium {
  font-size: 2.8rem;
  line-height: 1;
}

#pa-packages .package-premium .permonth{
  font-size: 14px; font-weight: normal;
}

#pa-packages .package-premium .thb{
  color: #6689aa;
  font-size: 60%;
}

#pa-packages .red-shadow .item-list .item {
  color: #003b71;
}
#pa-packages .item-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#pa-packages .item-list .item.inactive, #pa-packages .item-list .item.inactive .item-wrapper .item{
  color: #003b71 !important;
}

#pa-packages .item-list .item.inactive.nonselected{
  color: #003b71 !important;
}

#pa-packages .item-list .item .no-option{
    background: #e3e3e3;
    padding: 10px 10px 10px 52px;
    border-radius: 5px;
}

#pa-packages .item-list .item .no-option.active{
    color: #fff;
    background: #015caf;
}

#pa-packages .item-list .item .no-option .btn-circle{
  margin: 0 0 0 -42px;
  float: none;
}

#pa-packages .item-list .item .glyphicons {
  margin-left: -20px;
  margin-top: 0px;
}
#pa-packages .item-list .item .glyphicons.glyphicons-ok {
  color: #ff5011;
}

#pa-packages .custom-scroll {
  padding: 0 0 20px 0;
  overflow-x: auto;
  /* for scrollbar FF */
  scrollbar-color: #003b71 #dddddd;
  scrollbar-width: thin;
}

#pa-packages .custom-scroll > .item-wrapper {
  border: 0;
  margin: 20px 0 0 0;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: start;
}
/*#pa-packages .custom-scroll > .item-wrapper > .item {
  border-radius: 5px;
  background: #e3e3e3;
  width: 150px;
  display: block;
  padding: 8px 8px;
  margin: 0px;
  text-decoration: none;
}
#pa-packages .custom-scroll > .item-wrapper > .item.active {
  box-shadow: 0 4px 5px 2px rgba(243, 113, 113, 0.35);
  border: 1px solid #FF5011;
  background: #fff;
  font-weight: bold;
}*/

#pa-packages .custom-scroll > .item-wrapper > .item.active .price{
  display: none;
}

/*#pa-packages .custom-scroll > .item-wrapper > .item.active .cover-value {
  color: #FF5011;
  padding: 0;
  font-size: 13px;
}*/
#pa-packages .custom-scroll > .item-wrapper > .item.active .circle-check {
  background: #FF5011;
}
#pa-packages .custom-scroll > .item-wrapper > .item .circle-check {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #FFF;
  border: 1px solid #f1a386;
  display: inline-block;
  position: relative;
}
#pa-packages .custom-scroll > .item-wrapper > .item .circle-check.active {
  background: #FF5011;
}
#pa-packages .custom-scroll > .item-wrapper > .item .circle-check::before {
  content: " ";
  display: inline-block;
  transform: rotate(45deg);
  height: 15px;
  width: 9px;
  margin-left: 60%;
  border-bottom: 4px solid #FFF;
  border-right: 4px solid #FFF;
  position: absolute;
  top: 5px;
  left: 10px;
  margin: 0;
  padding: 0;
}
#pa-packages .custom-scroll > .item-wrapper > .item .desc {
  text-transform: uppercase;
  font-size: 11px;
  float: right;
}
#pa-packages .custom-scroll > .item-wrapper > .item .cover-value {
  font-weight: bold;
  display: block;
  text-align: right;
  margin: -7px 0 0 0;
}
#pa-packages .custom-scroll > .item-wrapper > .item .price {
  display: block;
  text-align: right;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
#pa-packages .custom-scroll::-webkit-scrollbar {
  height: 10px;
}
#pa-packages .custom-scroll::-webkit-scrollbar-track {
  background: #dddddd;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
#pa-packages .custom-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #003b71;
}
#pa-packages .custom-scroll::-webkit-scrollbar-thumb:window-inactive {
  background: #003b71;
}
#pa-packages .alike-card .btn-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #f1a386;
  display: inline-block;
  position: relative;
  margin: -10px 10px 0 0;
  float: left;
}
#pa-packages .alike-card .btn-circle.active {
  background: #ff5011;
}
#pa-packages .alike-card .btn-circle.active:before {
  content: " ";
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  height: 15px;
  width: 9px;
  margin-left: 60%;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  position: absolute;
  top: 5px;
  left: 10px;
  margin: 0;
  padding: 0;
}

.frame-summary{
  border: 2px solid #003b71;
  border-radius: 5px;
}

#pa-packages .floating-continue3x {
    position: fixed;
    bottom: 0;
    z-index: 999;
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
}

#pa-packages .floating-continue3x .floating-wrap {
    max-width: 600px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
    background-color: #196bb6;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#003b71),color-stop(75%,#003b71),to(#003b71));
    background-image: linear-gradient(180deg ,#003b71,#003b71 75%,#003b71);
}

#pa-packages .floating-continue3x .floating-wrap > .btn{
  display: block;
  border-radius: 10px;
  font-size: 14px;
  -webkit-box-shadow: 0 4px 5px 2px rgb(255 80 17 / 50%);
  box-shadow: 0 1px 6px 2px rgb(255 80 17 / 50%);
}

#pa-packages .btn-email{
  /* width: 33%; */
  background-color: #fff;
  color: #ff5011;
  margin-bottom: 10px;
  font-size: 17px;
}

#pa-packages .btn-callMeback{
  /* width: 33%; */
  background-color: #fff;
  color: #ff5011;
  margin-bottom: 10px;
  font-size: 17px;
}

#pa-packages .btn-wa{
  /* width: 33%; */
  color: #ff5011;
  background-color: #fff;
  margin-bottom: 10px;
}

#pa-packages .btn-buynow{
  /* width: 100%; */
  color: #fff;
  background-color: #ff5011;
}

#pa-packages #qotation-no .pnshow {
    margin: 0 auto 0 3px;
}

@-webkit-keyframes rubberBand {
    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }
    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }
    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }
}
@keyframes rubberBand {
    from,
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    30% {
        -webkit-transform: scale3d(1.25, .75, 1);
        transform: scale3d(1.25, .75, 1)
    }
    40% {
        -webkit-transform: scale3d(.75, 1.25, 1);
        transform: scale3d(.75, 1.25, 1)
    }
    50% {
        -webkit-transform: scale3d(1.15, .85, 1);
        transform: scale3d(1.15, .85, 1)
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1)
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1)
    }
}
#pa-packages .rubberBand {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand
}

#pa-packages .paymentOptions{
  text-align: center;
}

#pa-packages .paymentOptions .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin:0 10px -5px 10px;
}

#pa-packages .paymentOptions .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFAC50;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#pa-packages .paymentOptions .slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 0px;
  bottom: 0px;
  background-color: #FF5011;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#pa-packages .paymentOptions .slider.disabled{
  background-color: #f5f5f5!important;
}

#pa-packages .paymentOptions .slider.disabled:before {
  background: #f7fcff!important;
}

#pa-packages .paymentOptions .slider.annual:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

#pa-packages .paymentOptions .slider.round {
  border-radius: 50px;
}

#pa-packages .paymentOptions .slider.round:before {
  border-radius: 50%;
}

#pa-packages .paymentOptions .option{
  color: #e3e3e3;
  font-size: 1.3rem;
}

#pa-packages .paymentOptions .option.disabled{
  color: #e3e3e3 !important;
}

#pa-packages .paymentOptions .option.active{
  color: #FF5011;
}

#pa-packages .btn-selected{
  background-color: #196bb6;
  color: white;
  text-decoration: none;
  opacity: 1
}

#pa-packages .btn-selected:hover {
  color: #fff !important;
  text-decoration: none;
}

#pa-packages .btn-nonselected{
  background-color: #5d6d7e;
  color: white;
  text-decoration: none;
  opacity: 1
}

#pa-packages .btn-nonselected:hover {
  color: #fff !important;
  text-decoration: none;
}
.alert-va {
  background-color: #FFECE5;
  border: 2px solid #FF4713;
  border-radius:  8px;
  margin-bottom: 0px;
}
.info-icon {
  width: 30px;
  height: 30px;
}

.info-icon .fill-orange {
  fill: #FF4713;
}
.img-watermark{
  position: absolute;
  right: -5%;
  top: 30;
}
@media (max-width: 1150px){
  .img-watermark{
    right: -5%;
    top: 30;
  }
}

@media (max-width: 1024px){
  .img-watermark{
    right: -5%;
    top: 30;
  }
}@media (max-width: 960px){
  .img-watermark{
    right: -5%;
    top: 30;
  }
}
@media (max-width: 760px){
  .img-watermark{
    right: 0;
    top: 30;
    width: 120px;
  }
}
  @media (max-width: 540px){
    .img-watermark{
      right: 0;
      top: 30;
      width: 100px;
    }
  }
  @media (max-width: 430px){
    .img-watermark{
      right: 0;
      top: 30;
      width: 100px;
    }
  }
  @media (max-width: 360px){
    .img-watermark{
      right: -10;
      top: 30;
      width: 0;
    }
  }
</style>

<template>
  <div id="pa-packages" class="product-packages pt-5" data-selenium-name="isTeleselling" :data-selenium-value="Global.isTeleselling ? 'Yes' : 'No'">
    <header id="pa-packages-header">
      <nav>
      <div id="agent"
        style="padding: 5px 0px;color: #FFF;margin: 0px auto 5px auto;width: 100%;font-size: 12px;background-color: #003b71;"
        v-if="Global.brokerInfo.intermediaryReptEmail"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 text-right">
              {{ hideDomain(Global.brokerInfo.intermediaryReptEmail) }}
            </div>
          </div>
        </div>
      </div>
      <div class="container">
          <div class="row">
            <div class="col">
              <div class="nav-container">
                <button
                  type="button"
                  id="back-btn" 
                  data-selenium-name="goback-fix" 
                  @click="$router.push('/pa-input');" 
                  class="badge goback-btn"
                  style="border:1px solid #6993b9;border-radius:5px;"
                >
                  <svg
                    fill="#A8CEF1"
                    height="12"
                    width="12"
                    viewBox="0 0 24 24"
                    role="img"
                    style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
                  >
                    <path
                      d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                    ></path>
                  </svg>
                </button>
                <!-- <button type="button" id="back-btn" data-selenium-name="goback-fix" @click="$router.push('/ci-input');" class="badge goback-btn">
                  <svg fill="#A8CEF1" height="12" width="12" viewBox="0 0 24 24" role="img" style="transform: rotate(90deg);">
                    <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                  </svg>
                  {{ $t("back") }}
                </button> -->

                <!-- <button type="button" data-toggle="modal" data-selenium-name="btn-head-installment-fix" data-target="#installment-detail-modal" class="badge installment-btn" v-if="isAllowInstallment" v-on:click="eventLog('Instalment', 'Dropdown');">
                  {{ $t("instalment") }}
                  <svg fill="#A8CEF1" height="10" width="10" viewBox="1 0 24 24" role="img" style="transform: rotate(270deg);">
                    <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                  </svg>
                </button> -->
                  <div class="price-div" v-if="isDisplayInstallmentPrice && totalPremium > 0">
                    <div class="price" id="nav-totalPremium">
                      <span class="small">{{$t("currency")}} </span>
                      <strong class="total-price">{{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}</strong>
                      <span class="small">/{{$t("month")}}</span> <br>
                    </div>
                    <div class="mt-3" id="roojai-rewards">
                      <button
                        type="button"
                        class="badge installment-btn"
                        data-toggle="modal" data-selenium-name="btn-head-installment-fix" data-target="#installment-detail-modal" v-if="isAllowInstallment" v-on:click="eventLog('Instalment', 'Dropdown');"
                      >
                        {{ $t("instalmentOptions") }}
                        <svg
                          fill="#A8CEF1"
                          height="10"
                          width="10"
                          viewBox="1 0 24 24"
                          role="img"
                        >
                          <path
                            d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <!-- <div class="installment">
                      <span class="small">{{$t("instalment12months")}}</span>
                    </div> -->
                  </div>
                  <div class="price-div" v-else>
                    <div class="price" id="nav-totalPremium">
                      <span class="small">{{$t("currency")}} </span>
                        <strong class="total-price" data-selenium-name="totalPrice" :data-selenium-value="formatNumber(totalPremium)">{{formatNumber(totalPremium)}}</strong>
                      <span class="small">/{{$t("year")}}</span>
                    </div>
                  </div>
                </div>
              <!-- <div class="nav-container">
                <button type="button" id="back-btn" data-selenium-name="goback-fix" @click="$router.push('/pa-input');" class="badge goback-btn">
                  <svg fill="#A8CEF1" height="12" width="12" viewBox="0 0 24 24" role="img" style="transform: rotate(90deg);">
                    <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                  </svg>
                  {{ $t("back") }}
                </button>

                <button type="button" data-toggle="modal" data-selenium-name="btn-head-installment-fix" data-target="#installment-detail-modal" class="badge installment-btn" v-if="isAllowInstallment" v-on:click="eventLog('Instalment', 'Dropdown');">
                  {{ $t("instalment") }}
                  <svg fill="#A8CEF1" height="10" width="10" viewBox="1 0 24 24" role="img" style="transform: rotate(270deg);">
                    <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                  </svg>
                </button>

                <div class="price-div" v-if="isDisplayInstallmentPrice">
                  <div class="price" id="nav-totalPremium">
                    <span class="small">{{$t("currency")}} </span>
                    <strong class="total-price">{{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}</strong>
                    <span class="small">/{{$t("month")}}</span>
                  </div>
                  <div class="installment">
                    <span class="small">{{$t("instalment12months")}}</span>
                  </div>
                </div>
                <div class="price-div" v-else>
                  <div class="price" id="nav-totalPremium">
                    <span class="small">{{$t("currency")}} </span>
                      <strong class="total-price" data-selenium-name="totalPrice" :data-selenium-value="formatNumber(totalPremium)">{{formatNumber(totalPremium)}}</strong>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </nav>
    </header>

    <div class="container">
      <section id="quoteNoAndPremium" class="text-center mb-4">
        <div
          id="qotation-no"
          class="text-center light-blue mb-3"
          data-selenium-name="qotation-no"
          v-bind:data-selenium-value="Global.quote.opportunityNumber"
          v-bind:style="{
            visibility: Global.quote.opportunityNumber.startsWith('TBA')
              ? 'hidden'
              : ''
          }"
        >
          {{ $t("quotationNo") }}
          {{ Global.quote.opportunityNumber }}
        </div>
      </section>

      <div id="section-container">

        <div class="alike-card red-shadow mb-5">
          <div>
            <div class="paymentOptions mb-3">
              <h4 class="mb-3 text-center">
                {{$t("personalAccidentInsurance")}}
              </h4>
              <div v-if="!Global.isAgentCustomerView || (Global.isAgentCustomerView && Global.brokerInfo.useInstallment == true)">
                <strong class="option" v-bind:class="{'active': Global.paymentFrequency == '12 months, no deposit', 'disabled': !isAllowInstallment}">{{$t("switchMonthly")}}</strong>
  
                <div class="switch">
                  <a class="slider round" v-bind:class="{'disabled': !isAllowInstallment, 'annual': Global.paymentFrequency == 'Yearly'}" data-selenium-name="paymentOption" :data-selenium-value="Global.paymentFrequency" @click="switchPaymentFrequency( Global.paymentFrequency == 'Yearly' ? '12 months, no deposit' : 'Yearly' )"></a>
                </div>
  
                <strong class="option" v-bind:class="{'active': Global.paymentFrequency == 'Yearly', 'disabled': !isAllowInstallment}">{{$t("switchYearly")}} <small>({{$t("economical")}} {{Math.round((Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value-1)/(Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value)*100)}}%)</small></strong>
              </div>
            </div>

            <div
              class="package-premium"
              v-if="isDisplayInstallmentPrice"
            >
              <div class="price text-center" id="nav-totalPremium">
                <span class="small">{{$t("currency")}} </span>
                <strong class="total-price">
                  {{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}
                </strong> 
                <span class="small">/{{$t("month")}}</span> <br>
              </div>
            </div>

            <div
              class="package-premium"
              v-else
            >
              <div class="price text-center" id="nav-totalPremium">
                <span class="small">{{$t("currency")}} </span>
                <strong
                  class="total-price"
                  data-selenium-name="package-totalPremium"
                  :data-selenium-value="formatNumber(totalPremium)"
                >
                  {{formatNumber(totalPremium)}}
                </strong> 
                <span class="small">/{{$t("year")}}</span>
              </div>
            </div>

            <div class="alike-card mt-4">
              <div class="item-list mb-3">
                <div class="item mb-5" data-selenium-name="accidentalDeath" :data-selenium-value="Global?.selectedPackages[Global.coverName.PA]?.sumAssured.value">
                  <span class="glyphicons glyphicons-ok" ></span>

                  <strong>{{$t("accidentalDeath")}}</strong> ({{$t("accidentalDeathMethod")}})
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('accidentalDeathTooltip')" :data-original-title="$t('accidentalDeathTooltip')">?</a>
                  <br>

                  {{$t("accidentalDeathDesc")}}

                  <div class="custom-scroll mt-2" id="custom-scroll-death">
                    <div class="arrow">
                      <img src="images/left.svg" class="arrow-left" @click="scroll_left('death')" alt="">
                      <img src="images/right.svg" class="arrow-right" @click="scroll_right('death')" alt="">
                    </div>

                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item"
                      v-for="(option, index) in Global.packages?.coversList?.cover[0]?.optionList?.option" v-bind:key="index"
                      v-bind:class="{active : Global?.selectedPackages[Global.coverName.PA]?.sumAssured.value == option.sumAssured.value}"
                      data-selenium-name="accidentalDeath-option"
                      :data-selenium-value="index"
                      @click="Global.selectedPackages[Global.coverName.PA] = updateSelectedPackage(JSON.stringify(option), JSON.stringify(Global.selectedPackages[Global.coverName.PA]));
                      eventLog(Global.coverName.PA, option.sumAssured.value);"
                    >

                        <span class="circle-check"></span>
                        <span class="desc">{{getPackageLabel(index)}}</span>
                        <span class="cover-value"><small>{{$t("currency")}} </small>{{formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShowPA(updateSelectedPackage(JSON.stringify(option), JSON.stringify(Global.selectedPackages[Global.coverName.PA]))
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShowPA(updateSelectedPackage(JSON.stringify(option), JSON.stringify(Global.selectedPackages[Global.coverName.PA]))
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="item mb-5" v-if="additionalMedicalExpenses" v-bind:class="{inactive : !additionalMedicalExpenses?.isCovered}" data-selenium-name="additionalMedicalExpenses" :data-selenium-value="additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value">
                  <span class="glyphicons"
                    v-bind:class="giconsCoverage(additionalMedicalExpenses?.isCovered)"
                  ></span>

                  <strong>{{$t("medicalExpenses")}}</strong> ({{$t("medicalExpensesMethod")}})

                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('medicalExpensesTooltip')" :data-original-title="$t('medicalExpensesTooltip')">?</a>

                  <br>

                  {{$t("medicalExpensesDesc")}}

                  <div class="custom-scroll mt-1" id="custom-scroll-medical">
                    <div class="arrow">
                      <img src="images/left.svg" class="arrow-left" @click="scroll_left('medical')" alt="">
                      <img src="images/right.svg" class="arrow-right" @click="scroll_right('medical')" alt="">
                    </div>

                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in additionalMedicalExpenses?.options?.addCoveragesOptions?.addCoveragesOption" v-bind:key="index"
                        data-selenium-name="additionalMedicalExpenses-option"
                        :data-selenium-value="index"
                        v-bind:class="{active : additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value == option.sumAssured.value}"
                        @click="updateSelectedAdditionalOption(additionalMedicalExpenses?.options, option);
                        eventLog(additionalMedicalExpenses.options.additionalCoverageName.value, option.sumAssured.value);"
                      >
                        <span class="circle-check"></span>
                        <span class="desc">&nbsp;</span>
                        <span class="cover-value"><small v-if="option.sumAssured.value > 0">{{$t("currency")}} </small>{{ option.sumAssured.value == 0 ? $t("plans.notcover") : formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShow(additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- <div class="item mb-5" v-if="additionalDailyCash" v-bind:class="{inactive : !additionalDailyCash?.isCovered}" data-selenium-name="additionalDailyCash" :data-selenium-value="additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value">
                  <span class="glyphicons"
                    v-bind:class="giconsCoverage(additionalDailyCash?.isCovered)"
                  ></span>

                  <strong>{{$t("dailyCashBenefitWhileHospitalized")}}</strong> ({{$t("dailyCashBenefitWhileHospitalizedMethod")}})

                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('dailyCashBenefitWhileHospitalizedTooltip')" :data-original-title="$t('dailyCashBenefitWhileHospitalizedTooltip')">?</a>
                  <br>

                  <div v-if="additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value >= Global.PAminProofofIncome || (Global.phaccount.occupation.nameTh == 'Freelance' && Global.phaccount.Educational_Qualification != '') || (Global.phaccount.occupation.nameTh == 'Pemilik Bisnis' && Global.phaccount.Company_Revenue != '')" class="my-2">
                    <div class="alert alert-va d-flex align-items-stretch" style="max-width: 500px; margin-left: auto; margin-right: auto;">
                      <div class="d-flex align-items-center">
                        <svg class="info-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
                        <path class="fill-orange" d="M13.5,7.5h3v3h-3V7.5z M13.5,13.5h3v9h-3V13.5z M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z
                          M15,27C8.4,27,3,21.6,3,15S8.4,3,15,3s12,5.4,12,12S21.6,27,15,27z"/>
                        </svg>
                      </div>

                      <div class="text-black pl-3">
                        {{$t("minimumWaitingPeriodNotes")}}
                      </div>
                    </div> -->
                    <!-- <span style="color:red">{{$t("minimumWaitingPeriodNotes")}}</span> -->
                    <!-- <a href="javascript:void(0)" class="question-mark-red" data-toggle="modal" data-target="#minimum-waiting-period-popup">?</a> -->
                  <!-- </div>

                  {{$t("dailyCashBenefitWhileHospitalizedDesc")}}

                  <div class="custom-scroll mt-1" id="custom-scroll-dailycash">
                    <div class="arrow">
                      <img src="images/left.svg" class="arrow-left" @click="scroll_left('dailycash')" alt="">
                      <img src="images/right.svg" class="arrow-right" @click="scroll_right('dailycash')" alt="">
                    </div>

                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in additionalDailyCash?.options?.addCoveragesOptions?.addCoveragesOption" v-bind:key="index"
                        data-selenium-name="additionalDailyCash-option"
                        :data-selenium-value="index"
                        v-bind:class="{active : additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value == option.sumAssured.value}"
                        @click="updateSelectedAdditionalOption(additionalDailyCash?.options, option);
                        eventLog(additionalDailyCash.options.additionalCoverageName.value, option.sumAssured.value);"
                      >
                        <span class="circle-check"></span>
                        <span class="desc">&nbsp;</span>
                        <span class="cover-value"><small v-if="option.sumAssured.value > 0">{{$t("currency")}} </small>{{ option.sumAssured.value == 0 ? $t("plans.notcover") : formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShow(additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div> -->

                <div class="item" v-if="additionalFuneralExpenses" v-bind:class="{inactive : !additionalFuneralExpenses?.isCovered}" data-selenium-name="additionalFuneralExpenses" :data-selenium-value="additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value">
                  <span class="glyphicons"
                    v-bind:class="giconsCoverage(additionalFuneralExpenses?.isCovered)"
                  ></span>

                  <strong>{{$t("funeralExpenses")}}</strong> ({{$t("funeralExpensesMethod")}})

                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('funeralExpensesTooltip')" :data-original-title="$t('funeralExpensesTooltip')">?</a>

                  <br>

                  {{$t("funeralExpensesDesc")}}

                  <div class="custom-scroll mt-1" id="custom-scroll-funeral">
                    <div class="arrow">
                      <img src="images/left.svg" class="arrow-left" @click="scroll_left('funeral')" alt="">
                      <img src="images/right.svg" class="arrow-right" @click="scroll_right('funeral')" alt="">
                    </div>

                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in additionalFuneralExpenses?.options?.addCoveragesOptions?.addCoveragesOption" v-bind:key="index"
                        data-selenium-name="additionalFuneralExpenses-option"
                        :data-selenium-value="index"
                        v-bind:class="{active : additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value == option.sumAssured.value}"
                        @click="updateSelectedAdditionalOption(additionalFuneralExpenses?.options, option);
                        eventLog(additionalFuneralExpenses.options.additionalCoverageName.value, option.sumAssured.value);"
                      >
                        <span class="circle-check"></span>
                        <span class="desc">&nbsp;</span>
                        <span class="cover-value"><small v-if="option.sumAssured.value > 0">{{$t("currency")}} </small>{{ option.sumAssured.value == 0 ? $t("plans.notcover") : formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShow(additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="alike-card"
          v-if="additionalRidingMotorcycle || additionalMurderedOrAssaulted || additionalExtream"
          v-bind:class="[(additionalMurderedOrAssaulted?.selectedOption || additionalExtream?.selectedOption) ? 'red-shadow' : 'blue-shadow']"
        >
          <div>
            <h3 class="h5 text-left mb-3">
              {{$t("additionalCovers")}}
            </h3>

            <div class="item-list mb-3">
              <div class="item" v-if="additionalRidingMotorcycle" data-selenium-name="additionalRidingMotorcycle" :data-selenium-value="additionalRidingMotorcycle?.selectedOption ? 'Yes':'No'">
                <div class="no-option" v-bind:class="{active : additionalRidingMotorcycle?.selectedOption}">
                  <button class="btn btn-circle"
                    v-bind:class="{active : additionalRidingMotorcycle?.selectedOption}"
                    @click="updateSelectedAdditionalOption(additionalRidingMotorcycle?.options, additionalRidingMotorcycle?.selectedOption ? null : additionalRidingMotorcycle?.options?.addCoveragesOptions?.addCoveragesOption[0]);
                    eventLog(additionalRidingMotorcycle.options.additionalCoverageName.value, additionalRidingMotorcycle?.selectedOption ? additionalRidingMotorcycle?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value : 'NotCover');"
                  ></button>
                  {{$t("coveredRidingMotorcycle")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('coveredRidingMotorcycleTooltip')" :data-original-title="$t('coveredRidingMotorcycleTooltip')">?</a>
                </div>
              </div>

              <div class="item" v-if="additionalMurderedOrAssaulted" data-selenium-name="additionalMurderedOrAssaulted" :data-selenium-value="additionalMurderedOrAssaulted?.selectedOption ? 'Yes':'No'">
                <div class="no-option" v-bind:class="{active : additionalMurderedOrAssaulted?.selectedOption}">
                  <button class="btn btn-circle"
                    v-bind:class="{active : additionalMurderedOrAssaulted?.selectedOption}"
                    @click="updateSelectedAdditionalOption(additionalMurderedOrAssaulted?.options, additionalMurderedOrAssaulted?.selectedOption ? null : additionalMurderedOrAssaulted?.options?.addCoveragesOptions?.addCoveragesOption[0]);
                    eventLog(additionalMurderedOrAssaulted.options.additionalCoverageName.value, additionalMurderedOrAssaulted?.selectedOption ? additionalMurderedOrAssaulted?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value : 'NotCover');"
                  ></button>
                  {{$t("coveredMurderedAssaulted")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('coveredMurderedAssaultedTooltip')" :data-original-title="$t('coveredMurderedAssaultedTooltip')">?</a>
                </div>
              </div>

              <div class="item" v-if="additionalExtream" data-selenium-name="additionalExtream" :data-selenium-value="additionalExtream?.selectedOption ? 'Yes':'No'">
                <div class="no-option" v-bind:class="{active : additionalExtream?.selectedOption}">
                  <button class="btn btn-circle"
                    v-bind:class="{active : additionalExtream?.selectedOption}"
                    @click="updateSelectedAdditionalOption(additionalExtream?.options, additionalExtream?.selectedOption ? null : additionalExtream?.options?.addCoveragesOptions?.addCoveragesOption[0]);
                    eventLog(additionalExtream.options.additionalCoverageName.value, additionalExtream?.selectedOption ? additionalExtream?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value : 'NotCover');"
                  ></button>
                  {{$t("coveredWhileExtremeSport")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('coveredWhileExtremeSportTooltip')" :data-original-title="$t('coveredWhileExtremeSportTooltip')">?</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="section-1" class="section">
          <!-- <div class="alike-card red-shadow" >
            <div class="coverage2text mb-3">
              <h4 class="mb-3 text-center">
                {{"personalAccidentInsurance")}}
              </h4> -->

              <!-- <div class="pay2stage2 text-center mt-3 mb-4">
                <div class="paymentOptions mb-3">
                  <strong class="option" v-bind:class="{'active': Global.paymentFrequency == '12 months, no deposit', 'disabled': !isAllowInstallment}">{{$t("switchMonthly")}}</strong>
                  <div class="switch">
                    <a class="slider round" v-bind:class="{'disabled': !isAllowInstallment, 'annual': Global.paymentFrequency == 'Yearly'}" data-selenium-name="paymentOption" :data-selenium-value="Global.paymentFrequency" @click="switchPaymentFrequency( Global.paymentFrequency == 'Yearly' ? '12 months, no deposit' : 'Yearly' )"></a>
                  </div>
                  <strong class="option" v-bind:class="{'active': Global.paymentFrequency == 'Yearly', 'disabled': !isAllowInstallment}">{{$t("switchYearly")}}</strong>
                </div>

                <div
                  class="package-premium"
                  v-if="isDisplayInstallmentPrice"
                >
                  <div class="price" id="nav-totalPremium">
                    <span class="small">{{$t("currency")}} </span>
                    <strong class="total-price">
                      {{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}
                    </strong> 
                    <span class="small">/{{$t("month")}}</span> <br>
                  </div>
                </div>

                <div
                  class="package-premium"
                  v-else
                >
                  <div class="price" id="nav-totalPremium">
                    <span class="small">{{$t("currency")}} </span>
                    <strong
                      class="total-price"
                      data-selenium-name="package-totalPremium"
                      :data-selenium-value="formatNumber(totalPremium)"
                    >
                      {{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}
                    </strong> 
                    <span class="small">/{{$t("year")}}</span>
                  </div>
                </div>
              </div> -->

              <!--  <div class="item-list mb-3">
               <div class="item" data-selenium-name="accidentalDeath" :data-selenium-value="Global?.selectedPackages[Global.coverName.PA]?.sumAssured.value">
                  <span class="glyphicons glyphicons-ok" ></span>
                  {{$t("accidentalDeath")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="modal" data-target="#accidental-death">?</a>
                  <br>
                  <strong>{{$t("sumInsured")}}</strong>
                  <div class="custom-scroll mt-2">
                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item"
                      v-for="(option, index) in Global.packages?.coversList?.cover[0]?.optionList?.option" v-bind:key="index"
                      v-bind:class="{active : Global?.selectedPackages[Global.coverName.PA]?.sumAssured.value == option.sumAssured.value}"
                      data-selenium-name="accidentalDeath-option"
                      :data-selenium-value="index"
                      @click="Global.selectedPackages[Global.coverName.PA] = updateSelectedPackage(JSON.stringify(option), JSON.stringify(Global.selectedPackages[Global.coverName.PA]));
                      eventLog(Global.coverName.PA, option.sumAssured.value);"
                    >

                        <span class="circle-check"></span>
                        <span class="desc">{{getPackageLabel(index)}}</span>
                        <span class="cover-value"><small>{{$t("currency")}} </small>{{formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShowPA(updateSelectedPackage(JSON.stringify(option), JSON.stringify(Global.selectedPackages[Global.coverName.PA]))
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShowPA(updateSelectedPackage(JSON.stringify(option), JSON.stringify(Global.selectedPackages[Global.coverName.PA]))
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="item" v-if="additionalMedicalExpenses" v-bind:class="{inactive : !additionalMedicalExpenses?.isCovered}" data-selenium-name="additionalMedicalExpenses" :data-selenium-value="additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value">
                  <span class="glyphicons"
                    v-bind:class="giconsCoverage(additionalMedicalExpenses?.isCovered)"
                  ></span>
                  {{$t("medicalExpenses")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('medicalExpensesTooltip')" :data-original-title="$t('medicalExpensesTooltip')">?</a>
                  <br>
                  <strong>{{$t("upto")}}</strong>
                  <div class="custom-scroll mt-1">
                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in additionalMedicalExpenses?.options?.addCoveragesOptions?.addCoveragesOption" v-bind:key="index"
                        data-selenium-name="additionalMedicalExpenses-option"
                        :data-selenium-value="index"
                        v-bind:class="{active : additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value == option.sumAssured.value}"
                        @click="updateSelectedAdditionalOption(additionalMedicalExpenses?.options, option);
                        eventLog(additionalMedicalExpenses.options.additionalCoverageName.value, option.sumAssured.value);"
                      >
                        <span class="circle-check"></span>
                        <span class="desc">&nbsp;</span>
                        <span class="cover-value"><small v-if="option.sumAssured.value > 0">{{$t("currency")}} </small>{{ option.sumAssured.value == 0 ? $t("plans.notcover") : formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShow(additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="item" v-if="additionalDailyCash" v-bind:class="{inactive : !additionalDailyCash?.isCovered}" data-selenium-name="additionalDailyCash" :data-selenium-value="additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value">
                  <span class="glyphicons"
                    v-bind:class="giconsCoverage(additionalDailyCash?.isCovered)"
                  ></span>
                  {{$t("dailyCashBenefitWhileHospitalized")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('dailyCashBenefitWhileHospitalizedTooltip')" :data-original-title="$t('dailyCashBenefitWhileHospitalizedTooltip')">?</a>
                  <br>
                  <div v-if="additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value >= 1000000">
                    <span style="color:red">{{$t("minimumWaitingPeriodNotes")}}</span>
                    <a href="javascript:void(0)" class="question-mark-red" data-toggle="modal" data-target="#minimum-waiting-period-popup">?</a>
                    <br>
                  </div>
                  <strong>{{$t("upto")}}</strong>
                  <div class="custom-scroll mt-1">
                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in additionalDailyCash?.options?.addCoveragesOptions?.addCoveragesOption" v-bind:key="index"
                        data-selenium-name="additionalDailyCash-option"
                        :data-selenium-value="index"
                        v-bind:class="{active : additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value == option.sumAssured.value}"
                        @click="updateSelectedAdditionalOption(additionalDailyCash?.options, option);
                        eventLog(additionalDailyCash.options.additionalCoverageName.value, option.sumAssured.value);"
                      >
                        <span class="circle-check"></span>
                        <span class="desc">&nbsp;</span>
                        <span class="cover-value"><small v-if="option.sumAssured.value > 0">{{$t("currency")}} </small>{{ option.sumAssured.value == 0 ? $t("plans.notcover") : formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShow(additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="item" v-if="additionalFuneralExpenses" v-bind:class="{inactive : !additionalFuneralExpenses?.isCovered}" data-selenium-name="additionalFuneralExpenses" :data-selenium-value="additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value">
                  <span class="glyphicons"
                    v-bind:class="giconsCoverage(additionalFuneralExpenses?.isCovered)"
                  ></span>
                  {{$t("funeralExpenses")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('funeralExpensesTooltip')" :data-original-title="$t('funeralExpensesTooltip')">?</a>
                  <br>
                  <strong>{{$t("upto")}}</strong>
                  <div class="custom-scroll mt-1">
                    <div class="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in additionalFuneralExpenses?.options?.addCoveragesOptions?.addCoveragesOption" v-bind:key="index"
                        data-selenium-name="additionalFuneralExpenses-option"
                        :data-selenium-value="index"
                        v-bind:class="{active : additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value == option.sumAssured.value}"
                        @click="updateSelectedAdditionalOption(additionalFuneralExpenses?.options, option);
                        eventLog(additionalFuneralExpenses.options.additionalCoverageName.value, option.sumAssured.value);"
                      >
                        <span class="circle-check"></span>
                        <span class="desc">&nbsp;</span>
                        <span class="cover-value"><small v-if="option.sumAssured.value > 0">{{$t("currency")}} </small>{{ option.sumAssured.value == 0 ? $t("plans.notcover") : formatNumber(option.sumAssured.value)}}</span>
                        <span class="price">
                          <span class="glyphicons" v-bind:class="[premiumShow(additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.addPrem?.value
                                                                      , option.addPrem.value
                                                                      , (isDisplayInstallmentPrice)
                                                                    ).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div> -->

          <!-- <div class="alike-card mt-5"
            v-if="additionalRidingMotorcycle || additionalMurderedOrAssaulted || additionalExtream"
            v-bind:class="[(additionalMurderedOrAssaulted?.selectedOption || additionalExtream?.selectedOption) ? 'red-shadow' : 'blue-shadow']">
            <h3 class="h5 text-left mb-3">
              {{$t("additionalCovers")}}
            </h3>
            <div class="item-list mb-3">
              <div class="item" v-if="additionalRidingMotorcycle" data-selenium-name="additionalRidingMotorcycle" :data-selenium-value="additionalRidingMotorcycle?.selectedOption ? 'Yes':'No'">
                <div class="no-option" v-bind:class="{active : additionalRidingMotorcycle?.selectedOption}">
                  <button class="btn btn-circle"
                    v-bind:class="{active : additionalRidingMotorcycle?.selectedOption}"
                    @click="updateSelectedAdditionalOption(additionalRidingMotorcycle?.options, additionalRidingMotorcycle?.selectedOption ? null : additionalRidingMotorcycle?.options?.addCoveragesOptions?.addCoveragesOption[0]);
                    eventLog(additionalRidingMotorcycle.options.additionalCoverageName.value, additionalRidingMotorcycle?.selectedOption ? additionalRidingMotorcycle?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value : 'NotCover');"
                  ></button>
                  {{$t("coveredRidingMotorcycle")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('coveredRidingMotorcycleTooltip')" :data-original-title="$t('coveredRidingMotorcycleTooltip')">?</a>
                </div>
              </div>

              <div class="item" v-if="additionalMurderedOrAssaulted" data-selenium-name="additionalMurderedOrAssaulted" :data-selenium-value="additionalMurderedOrAssaulted?.selectedOption ? 'Yes':'No'">
                <div class="no-option" v-bind:class="{active : additionalMurderedOrAssaulted?.selectedOption}">
                  <button class="btn btn-circle"
                    v-bind:class="{active : additionalMurderedOrAssaulted?.selectedOption}"
                    @click="updateSelectedAdditionalOption(additionalMurderedOrAssaulted?.options, additionalMurderedOrAssaulted?.selectedOption ? null : additionalMurderedOrAssaulted?.options?.addCoveragesOptions?.addCoveragesOption[0]);
                    eventLog(additionalMurderedOrAssaulted.options.additionalCoverageName.value, additionalMurderedOrAssaulted?.selectedOption ? additionalMurderedOrAssaulted?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value : 'NotCover');"
                  ></button>
                  {{$t("coveredMurderedAssaulted")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('coveredMurderedAssaultedTooltip')" :data-original-title="$t('coveredMurderedAssaultedTooltip')">?</a>
                </div>
              </div>

              <div class="item" v-if="additionalExtream" data-selenium-name="additionalExtream" :data-selenium-value="additionalExtream?.selectedOption ? 'Yes':'No'">
                <div class="no-option" v-bind:class="{active : additionalExtream?.selectedOption}">
                  <button class="btn btn-circle"
                    v-bind:class="{active : additionalExtream?.selectedOption}"
                    @click="updateSelectedAdditionalOption(additionalExtream?.options, additionalExtream?.selectedOption ? null : additionalExtream?.options?.addCoveragesOptions?.addCoveragesOption[0]);
                    eventLog(additionalExtream.options.additionalCoverageName.value, additionalExtream?.selectedOption ? additionalExtream?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value : 'NotCover');"
                  ></button>
                  {{$t("coveredWhileExtremeSport")}}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t('coveredWhileExtremeSportTooltip')" :data-original-title="$t('coveredWhileExtremeSportTooltip')">?</a>
                </div>
              </div>
            </div>
          </div> -->
        </section>
      </div>
    </div>

    <div id="package-summary-container" style="min-height: 90vh;">
      <div class="container">
        <div class="row">
          <div class="col frame-summary">
            <div id="package-summary">
              <div class="p-3 frame-coverage">
                <h4 class="text-center mb-1">
                  <strong>{{ $t("yourCoverageInsurance") }}</strong>
                </h4>
              </div>
              <!-- <div class="text-center car2show">
                {{$t("yourIllnessPackage")}}
              </div> -->
              <!-- <h4 class="text-center mb-1">
                <strong>{{$t("yourCoverageInsurance")}}</strong>
              </h4> -->
              <div class="text-center mt-3 mb-3">
                <picture>
                  <source
                    media="(min-width:640px)"
                    :srcset="summaryPlanIcon"
                  />
                  <source
                    media="(max-width:415px)"
                    :srcset="summaryPlanIcon"
                  />
                  <img class="lazy" src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" :data-src="summaryPlanIcon" alt="Summary coverage" />
                </picture>
              </div>
              <center>
                <h5 class="text-center mb-1">
                  <strong>{{ $t("selected") }}</strong>
                </h5>
                <!-- <button class="btn btn-selected" v-bind:class="{'disabled': true}" :disabled="true">
                  {{ $t("selected") }}
                </button> -->
              </center>
              <br>
              <div style="position: relative; margin-bottom: 4rem;">
                  <div class="item-list pl-3">
                    <div class="item active">
                      <span class="glyphicons glyphicons-ok"></span>
                       {{$t("summaryLosslife")}}
                       <strong class="mr-1">{{$t("upto")}}</strong>
                      <span class="small" v-if="!isTHB">{{$t("currency")}} </span><strong>{{formatNumber(Global?.selectedPackages[Global.coverName.PA]?.sumAssured.value)}} </strong>
                    </div>

                    <div class="item active" v-if="additionalMedicalExpenses && additionalMedicalExpenses?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalMedicalExpenses?.isCovered)" ></span>
                      {{$t("medicalExpenses")}}
                        <template v-if="additionalMedicalExpenses?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value)}}</strong>
                        </template>
                    </div>

                    <!-- <div class="item active" v-if="additionalDailyCash && additionalDailyCash?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalDailyCash?.isCovered)" ></span>
                      {{$t("summaryDailyCashBenefitWhileHospitalized")}}
                        <template v-if="additionalDailyCash?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value)}}</strong>
                        </template>
                    </div> -->

                    <div class="item active" v-if="additionalFuneralExpenses && additionalFuneralExpenses?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalFuneralExpenses?.isCovered)" ></span>
                       {{$t("summaryFuneralExpenses")}}
                        <template v-if="additionalFuneralExpenses?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value)}}</strong>
                        </template>
                    </div>

                    <div class="item active" v-if="additionalRidingMotorcycle && additionalRidingMotorcycle?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalRidingMotorcycle?.isCovered)" ></span>
                       {{$t("summaryCoveredWhileRidingMotorcycle")}}
                        <template v-if="additionalRidingMotorcycle?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalRidingMotorcycle?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value)}}</strong>
                        </template>
                    </div>

                    <div class="item active" v-if="additionalMurderedOrAssaulted && additionalMurderedOrAssaulted?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalMurderedOrAssaulted?.isCovered)" ></span>
                       {{$t("summaryCoveredWhileMurderedAssaulted")}}
                        <template v-if="additionalMurderedOrAssaulted?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalMurderedOrAssaulted?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value)}}</strong>
                        </template>
                    </div>

                    <div class="item active" v-if="additionalExtream && additionalExtream?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalExtream?.isCovered)" ></span>
                       {{$t("summaryCoveredWhileExtremeSport")}}
                        <template v-if="additionalExtream?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalExtream?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value)}}</strong>
                        </template>
                    </div>
                  </div>
              </div>
              <center v-if="!additionalMedicalExpenses?.isCovered || !additionalDailyCash?.isCovered || !additionalFuneralExpenses?.isCovered || !additionalRidingMotorcycle?.isCovered || !additionalMurderedOrAssaulted?.isCovered || !additionalExtream?.isCovered">
                <h5 class="text-center mb-1">
                  <strong>{{ $t("nonselected") }}</strong>
                </h5>
                <!-- <button class="btn btn-nonselected" v-bind:class="{'disabled': true}" :disabled="true">
                  {{ $t("nonselected") }}
                </button> -->
              </center>
              <br>
              <div style="position: relative; margin-bottom: 4rem;">
                  <div class="item-list pl-3">
                    <div class="item inactive nonselected" v-if="additionalMedicalExpenses && !additionalMedicalExpenses?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalMedicalExpenses?.isCovered)" ></span>
                        <i>{{$t("medicalExpenses")}}</i>
                        <template v-if="additionalMedicalExpenses?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalMedicalExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value)}}</strong>
                        </template>
                    </div>

                    <!-- <div class="item inactive nonselected" v-if="additionalDailyCash && !additionalDailyCash?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalDailyCash?.isCovered)" ></span>
                        <i>{{$t("summaryDailyCashBenefitWhileHospitalized")}}</i>
                        <template v-if="additionalDailyCash?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalDailyCash?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value)}}</strong>
                        </template>
                    </div> -->

                    <div class="item inactive nonselected" v-if="additionalFuneralExpenses && !additionalFuneralExpenses?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalFuneralExpenses?.isCovered)" ></span>
                        <i>{{$t("summaryFuneralExpenses")}}</i>
                        <template v-if="additionalFuneralExpenses?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalFuneralExpenses?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value)}}</strong>
                        </template>
                    </div>

                    <div class="item inactive nonselected" v-if="additionalRidingMotorcycle && !additionalRidingMotorcycle?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalRidingMotorcycle?.isCovered)" ></span>
                        <i>{{$t("summaryCoveredWhileRidingMotorcycle")}}</i>
                        <template v-if="additionalRidingMotorcycle?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalRidingMotorcycle?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value)}}</strong>
                        </template>
                    </div>

                    <div class="item inactive nonselected" v-if="additionalMurderedOrAssaulted && !additionalMurderedOrAssaulted?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalMurderedOrAssaulted?.isCovered)" ></span>
                        <i>{{$t("summaryCoveredWhileMurderedAssaulted")}}</i>
                        <template v-if="additionalMurderedOrAssaulted?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalMurderedOrAssaulted?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value)}}</strong>
                        </template>
                    </div>

                    <div class="item inactive nonselected" v-if="additionalExtream && !additionalExtream?.isCovered">
                      <span class="glyphicons" v-bind:class="giconsCoverage(additionalExtream?.isCovered)" ></span>
                        <i>{{$t("summaryCoveredWhileExtremeSport")}}</i>
                        <template v-if="additionalExtream?.isCovered">
                          <strong>{{$t("upto")}}</strong>
                          <span class="small ml-1" v-if="!isTHB">{{$t("currency")}} </span>
                          <strong>{{formatNumber(additionalExtream?.selectedOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value)}}</strong>
                        </template>
                    </div>
                  </div>
              </div>

              <div class="row" v-if="!Global.isAgentTraditionalBroker">
                <div class="col-12 mb-2 order-sm-1">
                  <button
                    id="buy-now"
                    type="button"
                    data-selenium-name="buyNow"
                    :disabled="totalPremium < 1"
                    class="btn btn-block btn-lg btn-link-default buy-now"
                    @click="buynow(); eventLog('Buy_now', 'Details');"
                  >
                    {{ $t("confirmPackage") }}
                  </button>
                </div>
                <div class="col-12 mb-2">
                  <!-- <span style="margin:auto; display:table; color:rgb(255, 80, 17);">
                    {{ $t("eQuoteTitle") }}
                  </span> -->
                </div>
                <div class="col-12 col-md-7 mb-2 order-sm-3">
                  <a
                    href="javascript:void(0)"
                    id="roojai-call-back"
                    data-toggle="modal"
                    data-target="#roojai-call-back-modal"
                    data-selenium-name="roojai-call-back"
                    type="button"
                    class="btn btn-callMeback btn-block btn-lg"
                    style="border-color:#ff5011"
                    v-on:click="eventLog('call_me_back', 'text');"
                    ><span class="glyphicons glyphicons-earphone"></span>&nbsp;{{ $t("eQuoteTitleCall") }}
                  </a>
                </div>

                <div class="col-12 col-md-5 mb-2 order-sm-2 order-last">
                  <a
                    href="javascript:void(0)"
                    id="create-policy"
                    data-selenium-name="create-policy"
                    data-toggle="modal"
                    data-target="#email-policy-modal"
                    type="button"
                    class="btn btn-email btn-block btn-lg"
                    style="border-color:#ff5011"
                    v-on:click="eventLog('Receive_email', 'Details'); eventTracking('Oppo','quotationResultPackages', 'Opportunity_email_quote', 'PA');"
                    ><span class="glyphicons glyphicons-envelope" style="top:-1px"></span> &nbsp;{{ $t("eQuoteTitle") }}
                  </a>
                </div>
              <!-- <div class="col-12 col-md-4 mb-2">
                  <a
                    href="javascript:void(0)"
                    id="create-policy"
                    data-selenium-name="floating-Whatsapp"
                    data-toggle="modal"
                    data-target="#roojai-wa-modal"
                    type="button"
                    class="btn btn-link-blue btn-block btn-lg"
                    v-on:click="eventLog('whatsapp', 'Fixed_bottom');"
                    >{{ $t("eQuoteWhatsapp")}}
                  </a>
                </div> -->
              </div>
              
              <div class="row" v-else>
                <div class="col-12 col-md-6 mb-2">
                  <a
                    href="javascript:void(0)"
                    id="create-policy"
                    data-selenium-name="create-policy"
                    data-toggle="modal"
                    data-target="#email-policy-modal"
                    type="button"
                    class="btn btn-link-default btn-block btn-lg orange-text"
                    style="border-width: 2px; border-color: rgb(255, 80, 17); font-size:18px"
                    v-on:click="eventLog('Receive_email', 'Details');"
                    >{{ $t("eQuote") }}</a
                  >
                </div>
                <div
                  class="col-12 col-md-6 mb-4"
                >
                  <button
                    id="buy-now"
                    type="button"
                    data-selenium-name="buyNow"
                    :disabled="totalPremium < 1"
                    class="btn btn-block btn-lg btn-link-default buy-now"
                    @click="buynow(); eventLog('Buy_now', 'Details');"
                  >
                    {{ $t("confirmPackage") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin:auto">
      <div class="floating-continue3x">
        <div class="floating-wrap">
          <div class="col-12 mb-2 order-sm-1">
            <button
              class="btn btn-buynow btn-block"
              data-selenium-name="floating-buynow"
              :disabled="totalPremium < 1"
              @click="buynow();
              eventLog('Buy_now', 'Fixed_bottom');"
            >
              {{ $t("confirmPackage") }}
            </button>
          </div>

          <!-- <div class="col-12 mb-2 order-sm-2">
            <span style="margin:auto; display:table; color:#fff;">
              {{ $t("eQuoteGet") }}
            </span>
          </div> -->

          <div class="col-12 col-sm-5 order-sm-2 order-last">
            <button
              class="btn btn-block btn-email"
              data-selenium-name="floating-eQuote"
              data-toggle="modal"
              data-target="#email-policy-modal"
              @click="eventLog('Save_Quote', 'Fixed_bottom'); eventTracking('Oppo','quotationResultPackages', 'Opportunity_email_quote', 'PA');"
            >
              <span class="glyphicons glyphicons-envelope"></span>&nbsp;

              {{ $t("eQuoteTitle") }}

              <br />
            </button>
          </div>
          <!-- <div class="col-12 col-sm-4">
            <button class="btn btn btn-block btn-wa"
              data-selenium-name="floating-Whatsapp"
              data-toggle="modal"
              data-target="#roojai-wa-modal"
              @click="eventLog('whatsapp', 'Fixed_bottom');">
              {{ $t("eQuoteWhatsapp")}}
            </button>
          </div> -->
          <div class="col-12 col-sm-7 order-sm-3">
            <button
              class="btn btn-callMeback btn-block"
              data-selenium-name="floating-callMeback"
              data-toggle="modal"
              data-target="#roojai-call-back-modal"
              v-if="!Global.isAgentTraditionalBroker"
              @click="eventLog('call_me_back', 'Fixed_bottom');"
            >
              <span class="glyphicons glyphicons-earphone"></span>

              {{ $t("eQuoteTitleCall") }}

              <br />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="roojai-call-back-modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <!-- <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="callmeback-close"
            >
              X
            </button> -->
            <h5 class="modal-title mt-4 text-center h6">
              {{ Global.isAgentCustomerView ? $t("eQuoteAgent") : $t("callMeBack")}}
            </h5>
          </div>
          <div class="modal-body">
            <div class="mb-2 text-muted text-center" v-if="Global.isAgentCustomerView">
              {{ (Global.isAgentCustomerView ? $t("eQuoteExplainViaMobileAgent") :$t("eQuoteExplainViaMobile")) }}
            </div>
            <input
              type="tel"
              class="form-control input-lg"
              maxlength="15"
              v-model.trim="Global.phaccount.phoneNumber"
              :placeholder="$t('yourMobilePhone')"
              data-selenium-name="callMeBack-mobile"
            />
            <div class="text-muted mt-2">
              <span v-if="!Global.isAgentCustomerView" class="glyphicons glyphicons-info-sign" style="color:#003b71"></span>
              &nbsp;<span v-if="!Global.isAgentCustomerView">{{ $t("pleaseEnterYourMobileNumber") }}</span>
            </div>
          </div>
          <div class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2">
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="callmeback-close"
            >
              {{ $t("back") }}
            </button>
            <button
              type="button"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1"
              :disabled="!isValidPolicyMobile"
              @click="callMeBack(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_call_me_back', 'PA');"
              data-selenium-name="callmeback-submit"
            >
              {{ $t("roojaiCallmeback") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="roojai-wa-modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="wa-close"
            >
              X
            </button>
            <h5 class="modal-title mt-4 text-center h6">
              {{ $t("wacall") }}
            </h5>
          </div>
          <div class="modal-body">
            <input
              type="tel"
              class="form-control input-lg"
              maxlength="15"
              v-model.trim="Global.phaccount.phoneNumber"
              :placeholder="$t('yourMobilePhone')"
              data-selenium-name="waMeBack-mobile"
            />
            <div class="text-muted mt-2">
              <span class="glyphicons glyphicons-info-sign" style="color:#003b71"></span>
              &nbsp;<span>{{ $t("pleaseEnterYourWaNumber") }}</span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary w-100"
              :disabled="!isValidPolicyMobile"
              @click="waMeBack(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_wa_me', 'PA');"
              data-selenium-name="wameback-submit"
            >
              {{ $t("wa-call-button") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal-v1 fade"
      id="email-policy-modal"
      data-backdrop="static"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <h5 class="modal-title text-center h5" v-if="!Global.isAgentCustomerView">
              {{ $t("eQuote") }}
            </h5>
            <h5 class="modal-title text-center h5" v-if="Global.isAgentCustomerView && !emailPolicy.email && !emailPolicy.mobile">
              {{ $t("eQuote") }}
            </h5>
            <h5 class="modal-title text-center h5" v-if="Global.isAgentCustomerView && emailPolicy.email && !emailPolicy.mobile">
              {{ $t("eQuoteAgent") }}
            </h5>
            <h5 class="modal-title text-center h5" v-if="Global.isAgentCustomerView && emailPolicy.email && emailPolicy.mobile">
              {{ $t("eQuoteAmazing") }}
            </h5>
          </div>
          
          <div class="modal-body pb-3">
            <div id="emailPolicy-email" v-if="!emailPolicy.email">
              <input
                type="text"
                v-if="!Global.isAgentCustomerView"
                class="form-control input-lg"
                v-bind:class="{ 'text-center': isValidPolicyEmail }"
                placeholder="sample@email.com"
                v-model.trim="Global.phaccount.email"
                data-selenium-name="createPolicy-email"
              />
              <input
                v-if="Global.isAgentCustomerView"
                type="text"
                class="form-control input-lg"
                v-bind:class="{ 'text-center': isValidPolicyEmail }"
                placeholder="sample@email.com"
                v-model.trim="Global.phaccount.email"
                data-selenium-name="createPolicy-email"
              />
              <div
                class="btn-group-toggle label-checkbox mt-2"
                data-toggle="buttons"
              >
                <label v-if="!Global.isAgentCustomerView" class="btn btn-check" style="font-size:13px;color:#000">
                  <input
                    type="checkbox"
                    autocomplete="off"
                    @click="
                      Global.phaccount.PersonHasOptedOutOfEmail = !Global
                        .phaccount.PersonHasOptedOutOfEmail
                    "
                    :checked="Global.phaccount.PersonHasOptedOutOfEmail"
                    data-selenium-name="createPolicy-PersonHasOptedOutOfEmail"
                    :data-selenium-value="
                      !Global.phaccount.PersonHasOptedOutOfEmail ? 'No' : 'Yes'
                    "
                  />
                  {{ $t("emailOptedOut") }}
                </label>
                <div v-if="!Global.isAgentCustomerView" style="color: #FF5011!important; font-size:13px;">
                  {{ $t("getMoreDetails") }}
                </div>
                <div :class="{'text-center':Global.isAgentCustomerView}" style="font-size:16px;margin-top:1rem;font-weight:bold;">
                  {{ Global.isAgentCustomerView ? $t("getEmailAgent") : $t("getEmail") }}
                </div>
              </div>
            </div>

            <div
              id="emailPolicy-mobile"
              v-if="emailPolicy.email && !emailPolicy.mobile"
            >
              <div class="mb-2 text-muted text-center">
                {{ (Global.isAgentCustomerView ? $t("eQuoteExplainViaMobileAgent") :$t("eQuoteExplainViaMobile")) }}
              </div>
              <input
                type="tel"
                class="form-control input-lg"
                v-bind:class="{ 'text-center': isValidPolicyMobile }"
                :placeholder="$t('yourMobilePhone')"
                maxlength="15"
                v-model.trim="Global.phaccount.phoneNumber"
                data-selenium-name="createPolicy-mobile"
              />
              <div class="mt-4 text-center">
                <a
                  href="javascript:void(0)"
                  style="color: #FF5011!important;"
                  data-selenium-name="btn-createPolicy-skip"
                  @click="emailPolicyAction().skipMobile()"
                  >{{ $t("eQuoteSkipNext") }}</a
                >
              </div>
              <div class="mt-4 text-center" v-if="!Global.isAgentCustomerView">
                {{ $t("eQuoteNofee") }}
              </div>
            </div>

            <div
              id="emailPolicy-finished"
              v-if="emailPolicy.email && emailPolicy.mobile"
            >
              <div class="mt-4 text-center">
                <h6 class="text-default" v-if="!Global.isAgentCustomerView">{{ $t("eQuoteAmazing") }}</h6>
                <div>
                  {{ (Global.isAgentCustomerView ? $t("eQuoteDetailAgent") : $t("eQuoteDetail")) }}
                </div>
              </div>
            </div>
          </div>
          
          <div
            class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2"
            v-bind:key="'emailPolicy-email'"
            v-if="!emailPolicy.email"
          >
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1"
              data-dismiss="modal"
              @click="
                emailPolicy.email = false;
                emailPolicy.mobile = false;
              "
              data-selenium-name="btn-createPolicy-email-back"
            >
              {{ $t("cancel") }}
            </button>

            <button
              type="button"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1"
              :disabled="!isValidPolicyEmail"
              @click="emailPolicyAction().submitEmail(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_save_Email', 'PA');"
              data-selenium-name="btn-createPolicy-email-next"
            >
              {{ $t("submit") }}
            </button>
          </div>

          <div
            class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2"
            v-bind:key="'emailPolicy-mobile'"
            v-if="emailPolicy.email && !emailPolicy.mobile"
          >
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1"
              @click="emailPolicy.email = false"
              data-selenium-name="btn-createPolicy-mobile-back"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1"
              :disabled="!isValidPolicyMobile"
              @click="emailPolicyAction().submitMobile(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_save_Email_phone', 'PA');"
              data-selenium-name="btn-createPolicy-mobile-next"
            >
              {{ $t("submit") }}
            </button>
          </div>

          <div
            class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2"
            v-bind:key="'emailPolicy-finished'"
            v-if="emailPolicy.email && emailPolicy.mobile"
          >
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1"
              data-selenium-name="btn-createpolicy-customize"
              @click="emailPolicyAction().customize()"
            >
              {{ $t("eQuoteCustomize") }}
            </button>
            <button
              type="button"
              data-selenium-name="btn-createpolicy-buyNow"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1"
              :disabled="totalPremium < 1"
              @click="emailPolicyAction().confirm()"
            >
              {{ $t("confirmPackage") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="thankyou-modal-call" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            X
          </button>
          <div class="text-center">
            <img
              src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src="images/rj-thank-you.png"
              style="border-radius: 50%;border: 1px solid #eee;"
              class="lazy"
            />
          </div>
        </div>
        <div class="modal-body">
          <div
            class="text-default text-center pb-3"
            v-if="!Global.isAgentCustomerView"
          >
          {{ $t("thankyoucall") }} <br>
          {{ $t("thankyouweekday") }} <br>
          {{ $t("thankyouweekend") }}
          </div>
          <div
            class="text-default text-center pb-3"
            v-if="Global.isAgentCustomerView"
          >
            {{ $t("thankyoucallAgent") }}
          </div>
        </div>
      </div>
    </div>
  </div>

    <div class="modal modal-v1 fade" id="personal-liability" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" data-selenium-name="personal-liability-close">X</button>
            <h5 class="modal-title mt-4 text-center h5">
              {{ $t("personalLiability") }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              {{ $t("personalLiabilityModal.line1") }}
            </div>

            <div class="mb-3">
              {{ $t("personalLiabilityModal.line2") }}
              <ul type="circle">
                <li><span>{{ $t("personalLiabilityModal.list1") }}</span></li>
                <li><span>{{ $t("personalLiabilityModal.list2") }}</span></li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary w-100" data-dismiss="modal" data-selenium-name="personal-liability-btn-close">
              {{$t("close")}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="domestic-help-during-recovery" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" data-selenium-name="domestic-help-during-recovery-close">X</button>
            <h5 class="modal-title mt-4 text-center h5">
              {{ $t("domesticHelpDuringRecovery") }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              {{ $t("domesticHelpDuringRecoveryModal.description") }}
              <ol>
                <li><span>{{ $t("domesticHelpDuringRecoveryModal.list1") }}</span></li>
                <li><span>{{ $t("domesticHelpDuringRecoveryModal.list2") }}</span></li>
                <li><span>{{ $t("domesticHelpDuringRecoveryModal.list3") }}</span></li>
              </ol>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary w-100" data-dismiss="modal" data-selenium-name="domestic-help-during-recovery-btn-close">
              {{$t("close")}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="payment-change-to-yearly" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" data-selenium-name="loss-deafness-close" v-on:click="Global.paymentFrequency = 'Yearly'">X</button>
            <h5 class="modal-title mt-4 text-center h5">
                {{$t("paymentChangeToYearlyHeader")}}
              </h5>
          </div>
          <div class="modal-body text-center">
            {{$t("paymentChangeToYearly")}} <span class="small" v-if="!isTHB">{{$t("currency")}} </span>{{formatNumber(Global.PAminInstallmentTotalPremium)}}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary w-100"
              data-dismiss="modal"
              v-on:click="Global.paymentFrequency = 'Yearly'"
            >
              {{ $t("close") }}
            </button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";

export default {
  mixins: [Mixin],
  name: "Package",
  beforeRouteLeave (to, from, next) {
    if(to.name == 'policydetails1') next()
    if(window.pageYOffset > 0) {
      this.scrollToTopPage()
      next(false)
    } else next()
  },
  data() {
    return {
      Global,
      emailPolicy: {
        email: false,
        mobile: false
      }
    }
  },
  async created() {
    document.title = "Personal Accident Insurance"
    if (Global.radarCallType !== "1") {
      Global.radarCallType = "1";
      this.getPlanByProduct(null);
    }
    this.loading("show");
    try {
      // await this.getProductOptionDetails();
      this.loading("hide");
    } catch (error) {
      console.error(error);
    }
  },
  mounted() {
    var vm = this;
    this.loading("show", function() {
      setTimeout(function() {
        vm.loading("hide");
      }, 500);
    });

    window.scrollTo(0, 0);

    if (!this.isAllowInstallment) {
      Global.paymentFrequency = "Yearly"
    }

    this.bindScroll();
    this.mobileBack();
    this.itemScroll();
    $('[data-toggle="tooltip"]').tooltip();
  },
  methods: {
    scroll_left(product) {
      let content = document.querySelector("#custom-scroll-"+product);
      let scrollAmount = 0;
      let slideTimer = setInterval(function(){
          content.scrollLeft -= 10;
          scrollAmount += 10;
          if(scrollAmount >= 100){
              window.clearInterval(slideTimer);
          }
      }, 25);
      // content.scrollLeft -= 50;
    },
    scroll_right(product) {
      let content = document.querySelector("#custom-scroll-"+product);
      let scrollAmount = 0;
      let slideTimer = setInterval(function(){
        content.scrollLeft += 10;
        scrollAmount += 10;
        if(scrollAmount >= 100){
            window.clearInterval(slideTimer);
        }
      }, 25);
    },
    bindScroll:function(){
      $(window).off("scroll");
      $(window).on("scroll", function() {
        if ($(window).scrollTop() > 240) {
          $("#pa-packages-header").addClass("fixed-menu");
          $("body").addClass("scroll-down");
        } else {
          $("#pa-packages-header").removeClass("fixed-menu");
          $("body").removeClass("scroll-down");
        }

        //floating
        var packageSummary = $("#buy-now").offset().top - 50;
        var scrollBottom = $(window).height() + $(window).scrollTop();

        if( scrollBottom < packageSummary ){
          $(".floating-continue3x").show();
        }else{
          $(".floating-continue3x").hide();
        }
        //
      });
    },
    buynow: async function() {
      this.loading("show");
      Global.quote.onlineStage = "Proceed to Policy Details 1";
      // Global.quote.callMeBack = false;
      this.saveQuote();

      var category = "&c=" + Global.coverName.PA;
      try {
        await this.getProductQuestions("2", category);
        this.loading("hide");
        this.$router.push(Global.productConfig.route.policydetails1);
      } catch (error) {
        console.error(error);
      }
    },
    emailPolicyAction: function() {
      var vm = this;
      return {
        submitEmail: function() {
          vm.emailPolicy.email = true;
          Global.quote.sendEmailFlag = true;
          Global.quote.onlineStage = "Email me my quote";
          vm.saveQuote();
          Global.quote.sendEmailFlag = false;
          // $("#email-policy-modal").modal("hide");
        },
        submitMobile: function() {
          Global.quote.callMeBack = true;
          Global.quote.callMeBackRequest = "Yes";
          Global.quote.onlineStage = "Call me back";

          // if (Global.phaccount.lastName === "") {
          //   Global.phaccount.lastName = Global.phaccount.phoneNumber;
          // }
          var options = {
            callMeBack : true,
            callMeBackRequest : 'Yes'
          }
          vm.saveQuote(options);

          // if (Global.phaccount.lastName === Global.phaccount.phoneNumber) {
          //   Global.phaccount.lastName = "";
          // }
          // Global.quote.callMeBack = false;
          vm.emailPolicy.mobile = true;
          //send quote
        },
        skipMobile: function() {
          vm.emailPolicy.mobile = true;
          Global.quote.callMeBackRequest = ""
          Global.quote.callMeBack = null
          vm.saveQuote();
        },
        customize: function() {
          $("#email-policy-modal").modal("hide");
          vm.emailPolicy.email = false;
          vm.emailPolicy.mobile = false;
        },
        confirm: function() {
          $("#email-policy-modal").modal("hide");
          vm.emailPolicy.email = false;
          vm.emailPolicy.mobile = false;
          vm.saveQuote();

          vm.buynow();
        }
      };
    },
    mobileBack: function() {
      var vm = this;
      var root_url = window.location.href.split("/#/")[0];

      $(".modal").off("shown.bs.modal");
      $(".modal").on("shown.bs.modal", function(e) {
        history.pushState("packages", "", root_url + "/#" + vm.$route.path);
      });

      $(".modal").off("hidden.bs.modal");
      $(".modal").on("hidden.bs.modal", function(e) {
        history.replaceState("packages", "", root_url + "/#" + vm.$route.path);

        if (this.id == "email-policy-modal") {
          vm.emailPolicy.email = false;
          vm.emailPolicy.mobile = false;
        }
      });

      $(window).off("popstate");
      $(window).on("popstate", function(event) {
        $(".modal").modal("hide");
      });
    },
    callMeBack: function() {
      var options = {
        callMeBack: true,
        callMeBackRequest: 'Yes'
      }
      Global.quote.onlineStage = "Call me back";
      Global.quote.callMeBack = true;
      Global.quote.callMeBackRequest = "Yes";
      this.saveQuote(options)
      // this.callMeBackRequest( Global.phaccount.phoneNumber, options);
      $("#thankyou-modal-call").modal("show")
      setTimeout(function () {
            $("#thankyou-modal-call").modal("hide")
        }, 5000);
      $("#roojai-call-back-modal").modal("hide");
    },
    async waMeBack() {
      var vm = this;
      Global.quote.onlineStage = "WhatsApp me my quote";
      vm.saveQuote();
      var options = {
        callMeBackRequest : 'Yes'
      }
      await this.sendWhatsApp();
      if (Global.phaccount.sendWaStatus == 'success') {
        window.open('https://api.whatsapp.com/send?phone=622150890822&text=Ya saya mau terima penawaran sekarang', '_blank');
      }
      this.callMeBackRequest( Global.phaccount.phoneNumber, options);
      $("#roojai-wa-modal").modal("hide");
    },
    itemScroll:function(){
      $(".custom-scroll").each(function(){
          var scrollBoxWidth = $(this).width();
          var wrapperWidth = ($(this).find(".item").length * 150) + (($(this).find(".item").length -1) * 10);

          var scrollLeft = $(document).width() < 450 ? 120 : 106 ;

          var x = 150 * $(this).find(".item.active").index();


          if( wrapperWidth > scrollBoxWidth ){
            var activeIndex = $(this).find(".item.active").index();
            $(this).animate({scrollLeft: x}, 500);
          }
      });
    },
    getProductPackage: function(productName){
       if (Global.packages?.coversList?.cover) {
         return  Global.packages.coversList.cover.find(p => p.name.value.toLowerCase() === productName.toLowerCase());
       }
    },
    getPackageLabel: function(index) {
      // var label = "";

      // if (index == 0){
      //   label = this.$t("plans.saving");
      // } else if (index == 1){
      //   label = this.$t("plans.flexi");
      // } else if (index == 2){
      //   label = this.$t("plans.strong");
      // } else if (index == 3){
      //   label = this.$t("plans.family");
      // } else if (index == 4){
      //   label = this.$t("plans.premium");
      // }
      // return label;
      
      var label = this.$t("plans.premium");
      if (index == 0) label = this.$t("plans.value");
      if (index == 1) label = this.$t("plans.standard");
      if (index == 2) label = this.$t("plans.recommended");
      if (index == 3) label = this.$t("plans.premium");
      return label;
    },
    getAdditionalCoverages: function(name){
      var selectPackageOriginal = Global.packages?.coversList?.cover[0]?.optionList?.option.find(o => o.sumAssured.value == Global?.selectedPackages[Global.coverName.PA]?.sumAssured.value);
      let coveragesOptionsOriginal = selectPackageOriginal.coverages?.coverage.find(c => c.additionalCoverageName?.value === name);
      if(coveragesOptionsOriginal){
        let selectedAddCoveragesOption = this.getSelectedCoverageOption(name);
        return {
          options: this.filterOptions(coveragesOptionsOriginal)
          , selectedOption: selectedAddCoveragesOption
          , isCovered: selectedAddCoveragesOption && (
            (selectedAddCoveragesOption?.flagCoverageInterdependent?.value === "0"
              && selectedAddCoveragesOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssured?.value != 0)
            ||
            (selectedAddCoveragesOption?.flagCoverageInterdependent?.value === "1"
              && selectedAddCoveragesOption?.addCoveragesOptions?.addCoveragesOption[0]?.sumAssuredAddon?.value != 0))
        };
      } else {
        return null;
      }
    },
    filterOptions: function(coveragesOptionsOriginal){
      if(coveragesOptionsOriginal.addCoveragesOptions){
        let filterAddCoveragesOptions = coveragesOptionsOriginal.addCoveragesOptions?.addCoveragesOption.filter(
          addC => addC.sumAssured?.value > 0 || (addC.sumAssured?.value == 0 && coveragesOptionsOriginal.flagCoverageOptional?.value == 1) || addC.sumAssuredAddon?.value > 0);
        coveragesOptionsOriginal.addCoveragesOptions.addCoveragesOption = filterAddCoveragesOptions;
      }
      return coveragesOptionsOriginal
    },
    getSelectedCoverageOption: function(name){
      let selectedCoverageOption = Global.selectedPackages[Global.coverName.PA]?.coverages?.coverage.find(c => c.additionalCoverageName?.value == name);
      return selectedCoverageOption;
    },
    updateSelectedAdditionalOption: function(_selectCoverages, _option){
      try{
        let selectCoverages = JSON.parse(JSON.stringify(_selectCoverages));
        let existingCoverage = Global.selectedPackages[Global.coverName.PA].coverages.coverage.find(ops => ops.additionalCoverageName?.value == selectCoverages.additionalCoverageName?.value);
        if(_option){
          let selectOption = JSON.parse(JSON.stringify(_option));
          selectOption.isSelected = true;
          if(existingCoverage){
            existingCoverage.addCoveragesOptions.addCoveragesOption = [];
            existingCoverage.addCoveragesOptions.addCoveragesOption.push(selectOption);
            existingCoverage.additionalCoverageSumAssured.value = selectCoverages.flagCoverageInterdependent.value === "0" ? selectOption.sumAssured.value : selectOption.sumAssuredAddon.value;
          }else{
            selectCoverages.addCoveragesOptions.addCoveragesOption = [];
            selectCoverages.addCoveragesOptions.addCoveragesOption.push(selectOption);
            selectCoverages.additionalCoverageSumAssured.value = selectCoverages.flagCoverageInterdependent.value === "0" ? selectOption.sumAssured.value : selectOption.sumAssuredAddon.value;
            Global.selectedPackages[Global.coverName.PA].coverages.coverage.push(selectCoverages)
          }
        }else{
          let updateAddtionalCovers = [];
          for(let additionalCoverage in Global.selectedPackages[Global.coverName.PA].coverages.coverage){
            if(existingCoverage.additionalCoverageName?.value != Global.selectedPackages[Global.coverName.PA].coverages.coverage[additionalCoverage].additionalCoverageName?.value){
              updateAddtionalCovers.push(Global.selectedPackages[Global.coverName.PA].coverages.coverage[additionalCoverage]);
            }
          }
          Global.selectedPackages[Global.coverName.PA].coverages.coverage = updateAddtionalCovers;
        }
      }catch(e){
        console.error(e);
      }

    },
    premiumShow: function(currentOption, compareOption, isInstalment){
      var comparePremium = isInstalment ? Math.ceil(this.calculateOptionShowMonthly(currentOption - compareOption)) : this.calculatePrice(Number(currentOption - compareOption));
      var premiumShow = {
          "premium": Math.abs(comparePremium),
          "isMinus": Number(currentOption) > Number(compareOption)
        }
      return premiumShow;
    },
    premiumShowPA: function(compareOption, isInstalment){
      var currentPremium = isInstalment ? this.twelveMonthsNoDepositPremium.monthlyInstallment : this.totalPremium
      , comparePremium = isInstalment ? this.calculatePremiumShowMonthly(compareOption) : this.calculatePremiumShowYearly(compareOption)
      , premiumShow = {
          "premium": Math.abs(currentPremium - comparePremium),
          "isMinus": Number(currentPremium) > Number(comparePremium)
        }

      return premiumShow;
    },
    calculatePremiumShowYearly: function(selectedPackage){
      var netPremium = this.getPaPackagePremium(selectedPackage),
      premiumShow = this.calculatePrice(netPremium);

      return premiumShow;
    },
    calculatePremiumShowMonthly: function(selectedPackage){
      var netPremium = this.getPaPackagePremium(selectedPackage),
      monthly = (netPremium * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value);

      return (monthly + Math.ceil(0.004 * monthly))/12;
    },
    calculateOptionShowMonthly: function(option){
      var monthly = (option * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value);
      return Math.abs((monthly + Math.ceil(0.004 * monthly))/12);
    },
    getSubAdditionalCoverageSIMB(clauseCode){
      if(Global.percentageSubAdditionalCoverage !== null){
        var sumInsure = Global?.selectedPackages[Global.coverName.PA]?.sumAssured?.value;
        var percent = Number(Global.percentageSubAdditionalCoverage?.find(p => p.clauseCode === clauseCode)?.mbPercentage);
        return (sumInsure * percent)/100;
      }else {
        return 0;
      }
    },
    switchPaymentFrequency:function(frequency){
      if( !this.isAllowInstallment ){
        return false;
      }

      Global.paymentFrequency = frequency;
    },
    closePaymentChangeModal:function(){
      $("#payment-change-to-yearly").modal("hide");
      Global.paymentFrequency = 'Yearly';
    },
  },
  computed: {
    isValidPolicyEmail: function() {
      return (Global.isAgentCustomerView && Global.brokerInfo.intermediaryReptEmail != '') || this.validate(Global.phaccount.email).isEmail();
    },
    isValidPolicyMobile: function() {
      return this.validate(Global.phaccount.phoneNumber).isMobileNumber();
    },
    additionalPersonalAccident: function(){
      return this.getAdditionalCoverages('Personal Accident');
    },
    additionalMedicalExpenses: function(){
      return this.getAdditionalCoverages('Medical Expenses');
    },
    additionalDailyCash: function(){
      return this.getAdditionalCoverages('Daily Cash');
    },
    additionalFuneralExpenses: function(){
      return this.getAdditionalCoverages('Funeral Expenses');
    },
    additionalRidingMotorcycle: function(){
      return this.getAdditionalCoverages('2 Wheelers');
    },
    additionalMurderedOrAssaulted: function(){
      return this.getAdditionalCoverages('Murdered or Assaulted');
    },
    additionalExtream: function(){
      return this.getAdditionalCoverages('Extreme Sport');
    },
    additionalCompetitiveSport: function(){
      return this.getAdditionalCoverages('Competitive Sport');
    },
    additionalLossOfOrganOrSight: function(){
      return this.getAdditionalCoverages('PA - Loss of Organ or Sight');
    },
    summaryPlanIcon:function(){
      return Global.phaccount.gender == 'Male' ? 'images/plan-M.png' :  'images/plan-W.png';
    },
    selectedPlan:function(){
      var selected = Global?.selectedPackages[Global.coverName.PA]?.sumAssured.value;
      // var thOnly = Global.quote.prefLang == 'th' ? 'แผน':'';

      // if (selected == 100000) return thOnly + '' + this.$t("plans.saving");
      // if (selected == 300000) return thOnly + '' + this.$t("plans.flexi");
      // if (selected == 500000) return thOnly + '' + this.$t("plans.strong");
      // if (selected == 1000000) return thOnly + '' + this.$t("plans.family");
      // if (selected == 5000000) return thOnly + '' + this.$t("plans.premium");

      // return thOnly + '' + this.$t("plans.saving");
      
      var label = this.$t("plans.premium");
      if (index == 0) label = this.$t("plans.value");
      if (index == 1) label = this.$t("plans.standard");
      if (index == 2) label = this.$t("plans.recommended");
      if (index == 3) label = this.$t("plans.premium");
      return label;
    },
    isTHB:function(){
      return Global.quote.prefLang == 'th';
    }
  },
  updated() {
    this.itemScroll();
    this.bindScroll();
    $('[data-toggle="tooltip"]').tooltip("dispose");
    $('[data-toggle="tooltip"]').tooltip();
  },
  unmounted() {
    //fixed browser back
    $(".modal").off("shown.bs.modal");
    $(".modal").off("hidden.bs.modal");
    $(window).off("popstate");
    $(window).off("scroll");
    $(".item-wrapper").off();
  }
};
</script>
<i18n>
{
  "en": {
    "personalAccidentInsurance":"Personal Accident Insurance",
    "currency" :"IDR ",
    "extraCoverage": "EXTRA COVERAGE",
    "accidentalDeath":" Personal Accident",
    "accidentalDeathMethod":"Claim Reimbursement",
    "accidentalDeathDesc":"Lump sum amount for death & permanent disablement due to personal accident.",
    "accidentalDeathTooltip":"You or your beneficiary will receive 100% of the sum insured, in case of death, lost and not found within 60 days of the accident, or you suffer from total permanent disability. For partial permanent disability, you will receive a predetermined amount of the sum insured.",
    "medicalExpenses":" Medical Expenses",
    "medicalExpensesMethod":"Claim Cashless",
    "medicalExpensesDesc":"Cashless medical treatment or medication, up to the amount selected",
    "medicalExpensesTooltip":"Receive cashless treatment at over 2000 Roojai hospital network if you are hospitalized due to an accident.",
    "dailyCashBenefitWhileHospitalized":" Daily Cash Benefit",
    "dailyCashBenefitWhileHospitalizedMethod":"Claim Reimbursement",
    "dailyCashBenefitWhileHospitalizedDesc":"Cashless medical treatment or medication, up to the amount selected.",
    "dailyCashBenefitWhileHospitalizedTooltip":"Receive a fixed amount of cash every day while hospitalized due to accident for a maximum period of 20 days.",
    "funeralExpenses":"Funeral Expenses",
    "funeralExpensesTooltip":"Protect your family from adding to the burden of your loss the cost of the ceremony. Get 10% of the PA sum insured cash in case of funeral expenses",
    "funeralExpenses":" Funeral Expenses",
    "funeralExpensesMethod":"Claim Reimbursement",
    "funeralExpensesDesc":"Covers funeral expenses for up to amount selected",
    "funeralExpensesTooltip":"Protect your family from adding to the burden of your loss the cost of the ceremony. Get 10% of the PA sum insured cash in case of funeral expenses",
    "cosmeticReconstruct":"Cosmetic Reconstruction",
    "cosmeticReconstructTooltip":"It is hereby noted and agreed, that with the payment of additional premiums, this coverage is extended with the benefit of cosmetic or plastic surgery or treatment costs, or any treatment related to or required due to previous cosmetic treatments as a result of Accidents covered under this policy as stipulated in Chapter 1, Article 1 of this Policy, during the Policy Period. This benefit applies to reconstructive surgery if it is performed to restore function or appearance after an accident and it is done at a medically appropriate stage after the accident or surgery, and the costs of the treatment is duly approved by the Insurer in writing prior to the surgery. The maximum limit of the Insurer's liability for reimbursement of cosmetic reconstruction costs as stated  in the Policy Schedule. All other term and conditions of this Insurance Policy remain unaltered.",
    "personalLiability":"Personal Liability",
    "personalLiabilityModal":{
      "line1": "It is hereby noted and agreed, that with the additional premium payment, this coverage is extended to the benefit of compensation for the losses suffered by the Participant due to the third party's claim to the Policy and the Participant is legally responsible, with the benefit limit up to the insured price stated in the Policy Schedule.",
      "line2": "The claim reimbursement included herein shall comprise:",
      "list1": "Bodily injury or physical damage to the Third Party’s property being the responsibility of the Insured according to this Policy.",
      "list2": "Court charges and the remedies charges required in accordance with the written approval from the Insurer (maximum 10% of limit of Personal Liability). The court and remedies should be following the event of point above.",
    },
    "domesticHelpDuringRecovery":"Domestic help During Recovery (Nursing/Carers/Day-care)",
    "domesticHelpDuringRecoveryModal":{
      "description": "It is hereby noted and agreed, that with the payment of additional premiums, this coverage is extended that during the effective period specified herein, the insurer covers the Reasonable and Customary Charges for home nursing or in a convalescent home service subject to the limit  as stated under the plan in the Benefit Table max 30 (thirty) days when the Insurer has accepted a claim under Inpatient Treatment above, subject to Pre-Authorization and meets all the following criteria:",
      "list1": "Prescribed by the Insured’s treating Specialist Physician.",
      "list2": "Starts immediately upon the Insured’s discharge from Hospital.",
      "list3": "Nursing care provided by one (1) qualified nurse per day, where the nurse is needed to provide 	medical care, not personal assistance.",
    },
    "coveredRidingMotorcycle":"2 wheelers coverage (motorcycle & bicycle)",
    "coveredRidingMotorcycleTooltip":"Choose this option to extend your previous selected coverage (PA lump sum 100%, medical expense and funeral expense) In case of accident while riding or cycling.",
    "coveredMurderedAssaulted":"Accident due to Murdered or Assaulted",
    "coveredMurderedAssaultedTooltip":"Extend your previous selected coverage (PA lump sum 100%, medical and funeral expense) in case of assault or murdered. This case is usually being excluded from traditional policy.",
    "coveredWhileExtremeSport":"Accident while doing Extreme Sport",
    "coveredWhileExtremeSportTooltip":"Extend your previous selected coverage (PA lump sum 100%, medical and funeral expense) in case of accident while practicing extreme sport such as: Jet ski, parachute, boxing, diving, cycling(racing), ski racing including jet-ski, skate racing, boxing, parachute jumping (except for the purpose of life saving), while boarding or travelling in a hot-air balloon, gliding, bungee jumping, diving with oxygen tank and breathing equipment under water(scuba diving).",
    "coveredWhileCompetitiveSport":"Accident while doing Competitive Sport",
    "coveredWhileCompetitiveSportTooltip":"It is hereby noted and agreed, that with the payment of additional premiums, the insurer covers risks of Death, Permanent Disablement, Medical Expenses directly caused by a physically accident arising from the Insured’s participation as a competitor in sporting competitions, games, contests, rallies or their preparatory trials.",
    "coveredMedicalEvacuation":"Medical evacuation",
    "summaryLosslife":"Accidental death, dismemberment, loss of sight or total permanent disability",
    "summaryDailyCashBenefitWhileHospitalized":"Daily Cash Benefit while Hospitalized maximum 20 days",
    "summaryFuneralExpenses":"Funeral Expenses",
    "summaryCosmeticReconstruction":"Cosmetic Reconstruction",
    "summaryPersonalLiability":"Personal Liability",
    "summaryDomesticHelpDuringRecovery":"Domestic help During Recovery (Nursing/Carers/Day-care)",
    "summaryCoveredWhileRidingMotorcycle":"2 wheelers coverage (motorcycle & bicycle)",
    "summaryCoveredWhileMurderedAssaulted":"Accident due to Murdered or Assaulted",
    "summaryCoveredWhileExtremeSport":"Accident while doing Extreme Sport",
    "summaryCoveredWhileCompetitiveSport":"Accident while doing Competitive Sport",
    "summaryCoveredMedicalEvacuation":"Medical evacuation",
    "additionalCovers": "Additional coverages",
    "upto":"up to",
    "coverUpto":"up to",
    "cover":"",
    "uptoPerTime":"up to {0}",
    "perdayUptoDays":"per day",
    "thb":"",
    "minimumWaitingPeriodNotes":"Please remember to send your proof of income within 14 calendar days after your policy has been issued.",
  },
  "id": {
    "personalAccidentInsurance":"Asuransi Kecelakaan Diri",
    "extraCoverage": "PERTANGGUNGAN TAMBAHAN",
    "accidentalDeath":" Kecelakaan diri",
    "currency" :"Rp",
    "accidentalDeathMethod":"Claim Reimbursement",
    "accidentalDeathDesc":"Santunan tunai (dibayarkan sekaligus) untuk kematian dan cacat tetap karena kecelakaan.",
    "accidentalDeathTooltip":"Kamu atau ahli waris akan menerima 100% uang pertanggungan, jika meninggal dunia, hilang dan tidak ditemukan dalam waktu tidak kurang dari 60 hari sejak terjadinya kecelakaan, dan menderita cacat tetap total. Jika kamu mengalami cacat tetap sebagian, kamu akan menerima uang pertanggungan dengan besaran yang telah ditentukan.",
    "medicalExpenses":" Biaya Pengobatan Medis",
    "medicalExpensesMethod":"Claim Cashless",
    "medicalExpensesDesc":"Cashless untuk biaya perawatan medis atau pengobatan, sampai dengan jumlah yang dipilih.",
    "medicalExpensesTooltip":"Biaya pengobatan akibat kecelakaan kamu akan ditanggung menggunakan sistem cashless di lebih dari 2000 rumah sakit rekanan Roojai.",
    "dailyCashBenefitWhileHospitalized":" Santunan Tunai Harian",
    "dailyCashBenefitWhileHospitalizedMethod":"Claim Reimbursement",
    "dailyCashBenefitWhileHospitalizedDesc":"Santunan tunai harian selama rawat inap, maksimum 20 hari",
    "dailyCashBenefitWhileHospitalizedTooltip":"Dapatkan santunan tunai tetap harian selama rawat inap akibat kecelakaan, maksimal 20 hari.",
    "funeralExpenses":" Biaya Pemakaman",
    "funeralExpensesMethod":"Claim Reimbursement",
    "funeralExpensesDesc":"Santunan untuk biaya pemakaman sampai dengan jumlah yang dipilih.",
    "funeralExpensesTooltip":"Bebas khawatir dari biaya pemakaman. Dapatkan 10% dari total uang pertanggungan untuk biaya pemakaman!",
    "cosmeticReconstruct":"Rekontruksi Kosmetik",
    "cosmeticReconstructTooltip":"Dengan ini dicatat dan disetujui, bahwa dengan pembayaran tambahan premi, pertanggungan ini diperluas dengan manfaat biaya operasi atau perawatan kosmetik atau plastik, atau perawatan apa pun yang terkait atau diperlukan karena perawatan kosmetik sebelumnya  sebagai akibat dari Kecelakaan sebagaimana yang dimaksud pada   Pasal 1 Polis ini , selama Periode Polis. Manfaat ini  berlaku untuk pembedahan rekonstruktif jika dilakukan untuk mengembalikan fungsi atau penampilan setelah kecelakaan dan dilakukan pada tahap yang sesuai secara medis setelah kecelakaan atau pembedahan, dan biaya pengobatan telah disetujui oleh Penanggung secara tertulis sebelum operasi. Batas tanggung jawab tertinggi Penanggung untuk  penggantian biaya rekonstruksi kosmetik sesuai yang tercantum pada Ikhtisar Polis. Semua syarat dan ketentuan lain dari Polis Asuransi ini tetap tidak berubah.",
    "personalLiability":"Tanggung Jawab Hukum Personal",
    "personalLiabilityModal":{
      "line1": "Dengan ini dicatat dan disetujui, bahwa dengan pembayaran tambahan premi, pertanggungan ini diperluas dengan manfaat ganti rugi atas kerugian yang diderita Peserta akibat klaim Pihak Ketiga Polis dan Peserta bertanggung jawab secara hukum, dengan batas manfaat sampai dengan harga pertanggungan yang tercantum dalam Ikhtisar Polis.",
      "line2": "Penggantian klaim yang termasuk di sini terdiri dari:",
      "list1": "Cedera badan atau kerusakan fisik pada harta benda Pihak Ketiga menjadi tanggung jawab Peserta menurut Polis ini.",
      "list2": "Biaya pengadilan dan biaya pemulihan yang diperlukan sesuai dengan persetujuan tertulis dari Penanggung (maksimal 10% dari batas Tanggung Jawab Hukum Personal). Manfaat Pengadilan dan pemulihan mengikuti peristiwa pada poin 1 (satu) diatas.",
    },
    "domesticHelpDuringRecovery":"Bantuan Selama Pemulihan (Perawat/Pengasuh/Penitipan Anak)",
    "domesticHelpDuringRecoveryModal":{
      "description": "Dengan ini dicatat dan disetujui, bahwa dengan pembayaran tambahan premi, pertanggungan ini diperluas dengan manfaat penggantian Biaya yang Wajar dan Biasa untuk perawatan di rumah atau di layanan rumah pemulihan dengan tunduk pada batas sebagaimana dinyatakan dalam Tabel Manfaat, maksimal 30 (tiga puluh) hari ketika Penanggung menerima klaim  Perawatan Rawat Inap sebagai akibat dari Kecelakaan sebagaimana dimaksud pada  Pasal 1 Polis ini, selama Periode Polis, tunduk pada Pra-Otorisasi dan memenuhi semua kriteria berikut:",
      "list1": "Diresepkan oleh Dokter Spesialis yang merawat Tertanggung.",
      "list2": "Dimulai segera setelah Tertanggung keluar dari Rumah Sakit.",
      "list3": "Asuhan keperawatan yang diberikan oleh 1 (satu) perawat yang memenuhi syarat per hari, dimana perawat dibutuhkan untuk memberikan perawatan medis, bukan bantuan pribadi.",
    },
    "coveredRidingMotorcycle":"Kecelakaan saat mengendarai kendaraan roda 2 (motor & sepeda)",
    "coveredRidingMotorcycleTooltip":"Pilih opsi ini dan dapatkan proteksi yang kamu inginkan seperti 100% uang santunan kematian dan cacat tetap total, biaya pemakaman, dan uang santunan harian untuk melindungi kamu dari resiko kecelakaan akibat mengendarai kendaraan roda dua.",
    "coveredMurderedAssaulted":"Kecelakaan karena dibunuh atau diserang",
    "coveredMurderedAssaultedTooltip":"Kondisi ini bisanya dikecualikan dalam polis. Pilih opsi ini dan dapatkan proteksi yang kamu inginkan seperti 100% uang santunan kematian dan cacat tetap total, biaya pemakaman, dan uang santunan harian.", 
    "coveredWhileExtremeSport":"Kecelakaan saat melakukan Olahraga Ekstrim",
    "coveredWhileExtremeSportTooltip":"Dapatkan proteksi yang kamu inginkan seperti uang santunan tunai 100%, biaya perawatan dan pemakaman untuk melindungi kamu dari resiko kecelakaan saat melakukan olahraga ekstrim, seperti: Balap semua jenis mobil atau perahu, pacuan kuda, bersepeda, balap ski termasuk jet-ski, balap skate, tinju, lompat parasut (kecuali untuk tujuan menyelamatkan nyawa), saat menaiki atau bepergian di tempat yang panas. balon udara, gliding, bungee jumping, menyelam dengan tangki oksigen dan peralatan pernapasan di bawah air (Scuba diving).",
    "coveredWhileCompetitiveSport":"Kecelakaan saat melakukan Kompetisi Olahraga",
    "coveredWhileCompetitiveSportTooltip":"Dengan ini dicatat dan disetujui, bahwa dengan pembayaran tambahan premi, pertanggungan ini diperluas terhadap risiko Kematian, Cacat Tetap, Biaya Medis yang secara langsung disebabkan oleh kecelakaan fisik yang timbul dari keikutsertaan Tertanggung sebagai peserta dalam kompetisi olahraga, permainan, kontes, reli atau uji coba persiapannya.",
    "coveredMedicalEvacuation":"Evakuasi medis",
    "summaryLosslife":"Santunan kematian dan cacat tetap",
    "summaryDailyCashBenefitWhileHospitalized":"Santunan Tunai Harian selama Rawat Inap maksimal 20 hari",
    "summaryFuneralExpenses":"Biaya Pemakaman",
    "summaryCosmeticReconstruction":"Rekontruksi Kosmetik",
    "summaryPersonalLiability":"Tanggung Jawab Hukum Personal",
    "summaryDomesticHelpDuringRecovery":"Bantuan Selama Pemulihan (Perawat/Pengasuh/Penitipan Anak)",
    "summaryCoveredWhileRidingMotorcycle":"Kecelakaan saat mengendarai kendaraan roda 2 (motor & sepeda)",
    "summaryCoveredWhileMurderedAssaulted":"Kecelakaan karena dibunuh atau diserang",
    "summaryCoveredWhileExtremeSport":"Kecelakaan saat melakukan olahraga ekstrim",
    "summaryCoveredWhileCompetitiveSport":"Kecelakaan saat melakukan kompetisi olahraga",
    "summaryCoveredMedicalEvacuation":"Evakuasi medis",
    "additionalCovers": "Pertanggungan tambahan",
    "wa-call-button" : "Hubungi saya",
    "upto": "sampai dengan",
    "coverUpto": "Sampai dengan",
    "cover":"",
    "uptoPerTime":"sampai dengan {0}",
    "perdayUptoDays":"per hari",
    "thb":"",
    "minimumWaitingPeriodNotes":"Mohon mengirimkan bukti pendapatan dalam 14 hari kalender setelah polis dikeluarkan.",
  },
}
</i18n>